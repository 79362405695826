/* requires:
polyfill.js
*/

// host and testPrice values are not case sensitive
// groupId and tags value are case sensitive

const cart = {
  apiBaseUrl: 'cart_api_url_replaced_during_build',
  cartBaseUrl: 'cart_domain_replaced_during_build',

  init: () => {
    // remove this whole section when a solution for applying test price in cart exist
    // this removes and re-adds all the products on the cart with the correct prices
    const queryTestPrice = cart.getQueryString('tp') || 'a';
    const cookieTestPrice = cart.readCookie('_tp') || 'a';
    const queryCoupon = cart.getQueryString('cc');
    const cookieCoupon = cart.readCookie('_cc');
    const queryGroupId = cart.getQueryString('gid');
    const cookieGroupId = cart.readCookie('_gid');
    const localCart = JSON.parse(sessionStorage.getItem('localCart'));
    if (
      (queryTestPrice !== cookieTestPrice || queryCoupon !== cookieCoupon || queryGroupId !== cookieGroupId) &&
      localCart
    ) {
      console.log('Reinitializing the cart...');
      const cartId = localCart.cartId;
      const cartItems = localCart.cartProducts;
      for (let cartItem of cartItems) {
        const itemId = cartItem.cart_ProductId;
        const productId = cartItem.productId;

        const opts = {
          method: 'DELETE',
        };
        const url = `${cart.apiBaseUrl}/CartProducts/${cartId}/${itemId}`;

        fetch(url, opts)
          .then(checkFetchStatus)
          .then((data) => {
            if (data && data?.cartId) {
              // console.log(data)
              cart.addToCart(productId, true);
            } else {
              console.error('Item is not in the cart');
            }
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    }
    // end of workaround section

    cart.fetchCart();
    cart.updateCartLink();
    cart.initAddToCartBtns();
    cart.initAddBtnEvent();
  },

  fetchCart: (id) => {
    const cartId = id || cart.getCartId();
    const url = `${cart.apiBaseUrl}/Carts/${cartId}`;
    if (cartId) {
      fetch(url)
        .then(checkFetchStatus)
        .then((data) => {
          // console.log('item count: ', data.item_count);
          if (data && data?.cartProducts?.length) {
            // console.log(fetchedCart)
            // console.log(data.cartProducts)
            sessionStorage.setItem('localCart', JSON.stringify(data));
            if (data.cartId !== cart.readCookie('cartId')) {
              cart.setCookie('cartId', data.cartId, 7);
            }
            cart.updateCartItemCount(data);
            cart.updateCartLink();
            cart.initAddToCartBtns();
          } else {
            console.log('There are no items in the cart.');
            // clear cart storage and use new session from API
            cart.setCookie('cartId', null, -1);
            sessionStorage.removeItem('localCart');
            cart.updateCartItemCount(cart.getLocalCart());
            cart.updateCartLink();
            cart.initAddToCartBtns();
          }
        })
        .catch(function (err) {
          console.error(err);
        });
    }
  },

  addToCart: (productId, reAdding) => {
    const defaultHost = 'default_host_replaced_during_build';
    const defaultGroupId = 'default_group_replaced_during_build';
    const cartId = cart.getCartId();
    const url = `${cart.apiBaseUrl}/CartProducts`;
    const product = JSON.parse(sessionStorage.getItem('productsData')).find((item) => item.id === productId);
    const cartData = {
      productId: product.id,
      quantity: 1,
      pricingUsed: product.pricing.typePricing, // need to this pull from pricing data
      couponCode: cart.readCookie('_cc'), // need to this pull from the cookies _cc
      sourceHost: defaultHost, // need to this pull from config.js, there will only be one host per site
      groupId: product.pricing.groupId || defaultGroupId || null, // need to this pull from pricing data
    };

    // using the cartId if there's a session cartId
    if (cartId) {
      cartData.cartId = cartId;
    }

    const opts = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(cartData),
    };

    fetch(url, opts)
      .then(checkFetchStatus)
      .then((data) => {
        if (data.cartId) {
          // console.log(data);

          // reinitialized localCart with updated cart info
          if (data.cartId !== cart.readCookie('cartId')) {
            cart.setCookie('cartId', data.cartId, 7);
          }
          sessionStorage.setItem('localCart', JSON.stringify(data));
          cart.updateCartItemCount(data);
          cart.updateCartLink();
          cart.initAddToCartBtns();

          if (!reAdding) {
            cart.setCookie('cartId', data.cartId);
            cart.pushAddToCartDatalayer(cartData.productId);
          }
        } else {
          console.error('There are no items in the cart.');
        }
        // not needed right now because we are fetching the cart anyways which reinitializes the buttons
        // we will need this once the addToCart endpoint returns the full cart info
        if (!reAdding) {
          cart.initAddToCartBtns();
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  },

  pushAddToCartDatalayer: (productId) => {
    const pushData = (product) => {
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'USD',
          add: {
            products: [
              {
                name: product.name,
                id: product.id,
                price: product.pricing.discountAmount
                  ? product.pricing.price - product.pricing.discountAmount.toFixed(2).toString()
                  : product.pricing.price.toFixed(2).toString(),
                category: product.productCategory.categoryName,
                quantity: 1,
              },
            ],
          },
        },
      });
    };
    if (sessionStorage.getItem('productsData')) {
      const product = JSON.parse(sessionStorage.getItem('productsData')).find((item) => item.id === productId);
      pushData(product);
    }
  },

  getCartId: () => {
    return cart.readCookie('cartId');
  },

  getLocalCart: () => {
    return (
      JSON.parse(sessionStorage.getItem('localCart')) || {
        cartId: null,
        cartProducts: [],
      }
    );
  },

  updateCartItemCount: (cart) => {
    const itemCount = cart.cartProducts.reduce((previousItem, currentItem) => previousItem + currentItem.quantity, 0);
    const itemCountDisplays = document.querySelectorAll('a.view-cart .item-count');
    for (let itemCountDisplay of itemCountDisplays) {
      // console.log(itemCount)
      itemCountDisplay.innerHTML = itemCount || 0;
    }
  },

  updateCartLink: () => {
    const cartKey = cart.readCookie('cartId');
    const viewCartLinks = document.querySelectorAll('a.view-cart');
    for (let viewCartLink of viewCartLinks) {
      if (cartKey) {
        viewCartLink.href = cart.updateQueryString(viewCartLink.href, 'cartId', cartKey);
      }
    }
  },

  initAddBtnEvent: () => {
    document.addEventListener(
      'click',
      (e) => {
        const event = e ? e : window.event;
        const eventElement = event.target;
        const addToCartBtn = eventElement.closest('.add-to-cart-btn');
        if (
          addToCartBtn &&
          !addToCartBtn.classList.contains('go-to-cart') &&
          !addToCartBtn.classList.contains('view-cart-btn')
        ) {
          event.preventDefault();
          addToCartBtn.classList.add('btn-processing');
          const productId = addToCartBtn.dataset.id;
          cart.addToCart(productId);
        }
      },
      true
    );
  },

  initAddToCartBtns: () => {
    // adding addToCart eventlisteners
    const addToCartBtns = document.querySelectorAll('a.add-to-cart-btn');
    const localCartSession = cart.readCookie('cartId');
    const testPrice = cart.readCookie('_tp') ? `&tp=${cart.readCookie('_tp')}` : '';
    const couponCode = cart.readCookie('_cc') ? `&coupon=${cart.readCookie('_cc')}` : '';
    const groupId = cart.readCookie('_gid') ? `&groupId=${cart.readCookie('_gid')}` : '';

    for (let addToCartBtn of addToCartBtns) {
      const localCart = cart.getLocalCart();
      const productId = addToCartBtn.dataset.id;

      if (localCart?.cartProducts.some((product) => product.productId === productId) && localCartSession) {
        addToCartBtn.classList.remove('btn-processing', 'btn-primary');
        addToCartBtn.classList.add('view-cart-btn');
        addToCartBtn.innerHTML = 'View In Cart';
        addToCartBtn.href = `${cart.cartBaseUrl}/cart/?cartId=${cart.getCartId()}${testPrice}${couponCode}${groupId}`;
      } else {
        addToCartBtn.classList.remove('view-cart-btn');
        addToCartBtn.classList.add('btn-primary');
        addToCartBtn.innerHTML = 'Add To Cart';
      }

      // initialize the view in cart button with the correct URL
      if (addToCartBtn.classList.contains('go-to-cart')) {
        if (localCart?.cartProducts.some((product) => product.productId === productId) && localCartSession) {
          addToCartBtn.href = `${cart.cartBaseUrl}/cart/?cartId=${cart.getCartId()}${testPrice}${couponCode}${groupId}`;
          addToCartBtn.innerHTML = 'View In Cart';
        } else {
          addToCartBtn.href = `${cart.cartBaseUrl}/cart/?productId=${productId}${testPrice}${couponCode}${groupId}`;
          addToCartBtn.innerHTML = 'Add To Cart';
        }
      }
    }
  },

  readCookie: function (name) {
    let nameEQ = encodeURI(name) + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return decodeURI(c.substring(nameEQ.length, c.length));
    }
    return null;
  },

  setCookie: function (name, value, days) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    }
    const domain =
      location.hostname == 'localhost'
        ? ''
        : ';domain=.' + location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0];
    const security = location.hostname == 'localhost' ? '' : ';SameSite=None; Secure';
    document.cookie = name + '=' + value + expires + ';path=/' + domain + security;
  },

  getQueryString: function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  updateQueryString: function (uri, key, value) {
    let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    let separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      return uri + separator + key + '=' + value;
    }
  },
};

cart.init();
