/* requires:
polyfill.js
products.js
*/

// using host value to pull pricing for all products on a website
// API call will always need a host value
// host and testPrice values are not case sensitive
// groupId and tags value are case sensitive
// https://dlascservices.amersc.com/Products/api/v1/Public/GetProductsBy?host=Sandbox&groupId=Sandbox%20group&testPrice=A

// should the build pull and hardcode the prices blurring them for a few seconds?
// after the page loads, the api will be called again with any price modifier triggering price display
// use a combination of getElements* and querySelector* to improve performance
// used getElements* to get all price element, then select which one to update to add price display

const pricing = {
  init: function () {
    const productAPIUrl = 'product_api_url_replaced_during_build';
    const unpublishedData = pricing.readCookie('unpublished')
      ? !!+pricing.readCookie('unpublished')
      : 'product_unpublished_replaced_during_build';
    const defaultHost = 'default_host_replaced_during_build';
    const defaultGroupId = 'default_group_replaced_during_build';
    const queryTestPrice = pricing.getQueryString('tp', window.location.href) || pricing.readCookie('_tp') || null;
    const queryGroupId =
      pricing.getQueryString('gid', window.location.href) ||
      pricing.getQueryString('lgr', window.location.href) || // checking for LGR because some LGRs of NYSC are the group IDs
      pricing.readCookie('_gid') ||
      defaultGroupId ||
      null;
    const queryCoupon = pricing.getQueryString('cc', window.location.href) || pricing.readCookie('_cc') || null;

    // always saving the price modifiers values as cookie
    if (queryTestPrice) {
      pricing.setCookie('_tp', queryTestPrice.toLowerCase(), 30);
    }
    if (queryGroupId) {
      pricing.setCookie('_gid', queryGroupId.toLowerCase(), 30);
    }
    if (queryCoupon) {
      pricing.setCookie('_cc', queryCoupon.toLowerCase(), 30);
    }

    // setting cross-domain cookie for the cart
    pricing.setCookie('unpublished', unpublishedData === 'true' || unpublishedData === true ? 1 : 0);

    // fetch products data from API
    const fetchProducts = () => {
      const testPriceQuery = queryTestPrice ? `&testPrice=${queryTestPrice}` : '';
      const groupIdQuery = queryGroupId ? `&groupId=${queryGroupId}` : '';
      const couponQuery = queryCoupon ? `&couponcode=${queryCoupon}` : '';
      const unpublishedQuery = `&unpublished=${unpublishedData}`;

      // API url
      const productAPI = `${productAPIUrl}/GetProductsBy/?host=${defaultHost}${testPriceQuery}${groupIdQuery}${couponQuery}${unpublishedQuery}`;

      // calling the API here
      fetch(productAPI)
        .then(checkFetchStatus)
        .then((data) => {
          // console.log(data);
          pricing.displayPrice(data);
          pricing.storeProductData(data);

          // rendering dynamic product catalog, coming from the products.js file
          // set second param to true if we need to filter out non-reseller products
          renderProducts(data, false);
        })
        .catch(function (err) {
          console.error('API call failed', err);
        });
    };

    // check if product data is in storage
    const productsData = sessionStorage.getItem('productsData') && JSON.parse(sessionStorage.getItem('productsData'));

    if (productsData) {
      // use stored product data and update it by calling the API
      pricing.displayPrice(productsData);
      fetchProducts();
    } else {
      // call the API
      fetchProducts();
    }
  },

  displayPrice: function (products) {
    // find all price display elements
    // check if data-id value exist on the products array
    // grab pricing for that product
    // render the values in the DOM

    // for displaying the styled prices
    const priceStyledCollection = [...document.getElementsByClassName('price-styled')];
    for (let priceElement of priceStyledCollection) {
      const id = priceElement.dataset.id;
      const product = products.find((product) => product.id === id);
      const priceObj = product?.pricing;
      const amount = priceObj?.discountAmount
        ? (priceObj?.price - priceObj?.discountAmount).toFixed(2).toString()
        : priceObj?.price?.toFixed(2).toString();
      const amountDollars = amount ? amount.split('.')[0] : null;
      const amountCents = amount ? (amount.split('.')[1] ? amount.split('.')[1] : '00') : null;
      priceElement.getElementsByClassName('dollars')[0].innerHTML = amountDollars;
      priceElement.getElementsByClassName('cents')[0].innerHTML = amountCents;
      // if cents is equal to 00 display nothing
      amountCents === '00' && priceElement.getElementsByClassName('cents')[0].classList.add('display-none');
      priceElement.classList.add('shown');
    }

    // for displaying the unstyled prices
    const priceDisplayCollection = [...document.getElementsByClassName('price-display')];
    for (let priceElement of priceDisplayCollection) {
      const id = priceElement.dataset.id;
      const product = products.find((product) => product.id === id);
      const priceObj = product?.pricing;
      // if there's priceAfterDiscount display priceAfterDiscount else display the price
      const amount =
        '$' +
        (priceObj?.discountAmount
          ? (priceObj?.price - priceObj?.discountAmount).toFixed(2).toString()
          : priceObj?.price?.toFixed(2).toString());
      priceElement.innerHTML = amount;
    }

    // for displaying the the strikethrough prices
    const priceRegularCollection = [...document.getElementsByClassName('regular-price')];
    for (let priceElement of priceRegularCollection) {
      const id = priceElement.dataset.id;
      const product = products.find((product) => product.id === id);
      const priceObj = product?.pricing;
      const amount = '$' + priceObj?.price?.toFixed(2).toString();
      priceElement.innerHTML = amount;
      // if there's no priceAfterDiscount display nothing
      priceElement.classList.remove('display-none');
      priceElement.closest('.regular-price-display').classList.remove('display-none');
      !priceObj?.discountAmount && priceElement.classList.add('display-none');
      !priceObj?.discountAmount && priceElement.closest('.regular-price-display').classList.add('display-none');
    }

    // for displaying the amount of savings in case we need to
    const savingsAmountCollection = [...document.getElementsByClassName('savings-amount')];
    for (let priceElement of savingsAmountCollection) {
      const id = priceElement.dataset.id;
      const product = products.find((product) => product.id === id);
      const priceObj = product?.pricing;
      const amount = '$' + priceObj?.discountAmount?.toFixed(2).toString();
      priceElement.innerHTML = amount;
      // if there's no discountAmount display nothing
      priceElement.classList.remove('display-none');
      priceElement.closest('.savings-display').classList.remove('display-none');
      !priceObj?.discountAmount && priceElement.classList.add('display-none');
      !priceObj?.discountAmount && priceElement.closest('.savings-display').classList.add('display-none');
    }
  },

  storeProductData: function (products) {
    const productsArr = [];
    for (let product of products) {
      const productObj = {
        id: product.id,
        name: product.name,
        productCategory: {
          subCategoryName: product.productCategory.subCategoryName,
          categoryName: product.productCategory.categoryName,
          verticalName: product.productCategory.verticalName,
        },
        tags: [...product.tags],
        pricing: {
          price: product.pricing.price,
          discountAmount: product.pricing.discountAmount,
          priceAfterDiscount: product.pricing.priceAfterDiscount,
          discountMethod: product.pricing.discountMethod,
          typePricing: product.pricing.typePricing,
          groupId: product.pricing.groupId,
          // coupon: product.pricing.coupon, // removing since its not being return by the API 04/20/2023
        },
      };
      productsArr.push(productObj);
    }
    sessionStorage.setItem('productsData', JSON.stringify(productsArr));
  },

  readCookie: function (name) {
    let nameEQ = encodeURI(name) + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return decodeURI(c.substring(nameEQ.length, c.length));
    }
    return null;
  },

  setCookie: function (name, value, days) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toGMTString();
    }
    const domain =
      location.hostname == 'localhost'
        ? ''
        : ';domain=.' + location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0];
    const security = location.hostname == 'localhost' ? '' : ';SameSite=None; Secure';
    document.cookie = name + '=' + value + expires + ';path=/' + domain + security;
  },

  getQueryString: function (name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  updateQueryString: function (uri, key, value) {
    let re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    let separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + '=' + value + '$2');
    } else {
      return uri + separator + key + '=' + value;
    }
  },
};

pricing.init();
